import Head from "next/head";
import { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { PageErrorBoundary } from "./error/PageErrorBoundary";
import PageContainer from "./fundamentals/page/PageContainer";
import { ReactQueryUtility } from "../feature/operational-tooling/ReactQueryUtility";
import { BaseNavigation } from "@/feature/navigation/components/BaseNavigation";

interface DataProps {
  readonly children?: ReactNode;
  readonly browserTitle?: string;
  readonly pageTitle?: string | ReactNode;
  readonly pageIdentifier?: string;
}

export const PublicLayout = (props: DataProps) => {
  const { children, browserTitle } = props;

  return (
    <>
      <Head>
        <title>{browserTitle ? `${browserTitle} | Compound Planning` : "Compound Planning"}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <div className="flex flex-col" data-page={props.pageIdentifier}>
        <BaseNavigation logoLink="https://compoundplanning.com/" />

        <div className="flex max-h-screen-wo-nav w-screen overflow-y-auto">
          <PageContainer title={props.pageTitle}>
            <ErrorBoundary FallbackComponent={PageErrorBoundary}>{children}</ErrorBoundary>
          </PageContainer>
        </div>
      </div>
      <ReactQueryUtility />
    </>
  );
};

export default PublicLayout;
