import { Disclosure } from "@headlessui/react";
import Image from "next/image";
import Link from "next/link";
import nextFutureImageLoader from "shared/utilities/nextFutureImageLoader";

export const NavigationLogo = () => (
  <div className="ml-[8px]">
    <Image
      src="/v1698342424/compound-logo-sm.svg"
      loader={nextFutureImageLoader}
      height={28}
      width={28}
      alt="Compound Planning logo"
      data-at="compound-logo"
    />
  </div>
);

type BaseNavigationLink = {
  readonly logoLink: string;
};

export const BaseNavigation = (props: BaseNavigationLink) => (
  <Disclosure as="nav" className="border-b border-gray-200 bg-white">
    <div className="max-w-7xl px-4">
      <div className="flex h-16 items-center justify-between">
        <Link href={props.logoLink}>
          <NavigationLogo />
        </Link>
      </div>
    </div>
  </Disclosure>
);
