import { useUser } from "@auth0/nextjs-auth0/client";
import { datadogRum } from "@datadog/browser-rum";
import React, { useEffect, useState } from "react";
import {
  APP_ENV_LOCAL,
  getClientSafeAppStage,
  getClientSafeDatadogEnv,
} from "shared/environmentUtils";
import { ampli } from "src/ampli";

export const Monitoring = () => {
  const { user } = useUser();
  const stage = getClientSafeAppStage();
  const [datadogInitCalled, setDatadogInitCalled] = useState<boolean>(false);

  useEffect(() => {
    if (stage !== APP_ENV_LOCAL) {
      if (!datadogInitCalled) {
        datadogRum.init({
          applicationId: "b772efec-c0d9-4eeb-ac3c-e075911c2df0",
          clientToken: "pubbb78c5e0a024155ce236f38e57f5f414",
          site: "datadoghq.com",
          service: "contro-app",
          // Use `production` to match the `compound` datadog application
          env: getClientSafeDatadogEnv(),
          version: "1.0.0",
          allowedTracingUrls: [
            /https:\/\/.*\.compoundplanning\.com/,
            /https:\/\/.*\.alternativ\.com/,
          ],
          defaultPrivacyLevel: "mask-user-input",
          sessionReplaySampleRate: 100,
          sessionSampleRate: 100,
          trackLongTasks: true,
          trackResources: true,
          trackUserInteractions: true,
        });
        setDatadogInitCalled(true);
      }

      if (typeof user?.id === "string") {
        datadogRum.setUser({
          id: user.id,
          name: user.name ?? undefined,
        });
      }
    }
  }, [user, stage, datadogInitCalled]);

  useEffect(() => {
    if (!ampli.isLoaded) {
      ampli.load({ environment: "gabetest" });
    }

    if (typeof user?.id === "string") {
      ampli.identify(user.id, {
        email: user.email ?? undefined,
        name: user.name ?? undefined,
      });
    }
  }, [user, stage]);

  return <></>;
};
